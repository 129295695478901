















































































































































































































import {Component, Vue} from "vue-property-decorator";
import DocumentService from "@/services/DocumentService";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import {namespace} from "vuex-class";
import {STATE_LIST} from "@/constants/ProfileConstants";
import SaveW4RequestDTO from "@/dto/archive/SaveW4RequestDTO";
import AutoComplete from "@/components/common/AutoComplete.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import AutoCompleteDTO from "@/components/common/AutoCompleteDTO";
import {ifValid} from "@/utils/ComponentUtils";
import RouteNames from "@/router/RouteNames";
import {FillableDocumentStatus} from "@/dto/archive/FillableDocumentStatus";

const AppModule = namespace("App");

@Component({
  components: {PortalInput, AutoComplete},
	computed: {
		AutoCompleteDTO() {
			return AutoCompleteDTO
		},
    FillableDocumentStatus() {
      return FillableDocumentStatus;
    }
	}
})
export default class FormW4 extends Vue {

	private successful = false;

	private message = "";

	private states = STATE_LIST

	@AppModule.State
	private loading!: boolean;

	@AppModule.Action
	private startLoading!: () => void

	@AppModule.Action
	private stopLoading!: () => void

	private exists = true;

	private processed = false;

	private request = new SaveW4RequestDTO();


	public created(): void {
    if(this.$route.name == RouteNames.PUBLIC_FORMS_W4) {
      this.request.publicId = this.$route.params.publicId;
		  this.loadPublicForm();
    } else{
      this.request.id = Number.parseInt(this.$route.params.id);
      this.loadInternalForm();
    }
	}

	private loadPublicForm(): void{
		this.startLoading();
		DocumentService.loadPreparedPublicFormW4(this.$route.params.publicId).then(
			success => {
        if (success.data.status === FillableDocumentStatus.PENDING_SIGNATURE) {
          this.goToSignature(success.data.signerPublicId as string)
        } else {
          this.request.merge(success.data);
        }
				this.stopLoading();
			},
			error => {
				this.exists = false;
				this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
				this.successful = false;
				this.stopLoading();
			});
	}

	private loadInternalForm(): void{
		this.startLoading();
		DocumentService.loadPreparedFormW4(Number.parseInt(this.$route.params.id)).then(
			success => {
				this.request.merge(success.data);
				this.stopLoading()
			},
			error => {
				this.exists = false;
				this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string
				this.successful = false;
				this.stopLoading()
			});
	}

	private save(): void {
    if(this.$route.name === RouteNames.PUBLIC_FORMS_W4) {
      this.request.publicId = this.$route.params.publicId;
      this.savePublicForm();
    } else {
      this.request.id = Number.parseInt(this.$route.params.id);
      this.saveInternalForm();
    }
	}

  private saveInternalForm(): void {
    ifValid(this, () => {
      this.message = "";
      this.startLoading();
      DocumentService.saveW4(this.request).then(
        success => {
          this.successful = true;
          this.processed = true;
          this.$router.push({name: RouteNames.ARCHIVE_W4});
        },
        error => {
          this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
        }
      ).then(() => this.stopLoading());
    })
  }

  private savePublicForm(): void {
    ifValid(this, () => {
      this.message = "";
      this.startLoading();
      DocumentService.savePublicW4(this.request).then(
        success => {
          this.successful = true;
          this.processed = true;
          this.goToSignature(success.data);
        },
        error => {
          this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
        }
      ).then(() => this.stopLoading());
    })
  }

  private goToSignature(publicId: string): void {
    this.$router.push({name: RouteNames.SIGN_PUBLIC, params: { publicId }});
  }

	private onSingle(): void {
		this.request.isSingleMarriedSeparately = true;
		this.request.isMarriedOrWidow = false;
		this.request.isHeadOfHousehold = false;
	}

	private onMarried(): void {
		this.request.isSingleMarriedSeparately = false;
		this.request.isMarriedOrWidow = true;
		this.request.isHeadOfHousehold = false;
	}

	private onHoh(): void {
		this.request.isSingleMarriedSeparately = false;
		this.request.isMarriedOrWidow = false;
		this.request.isHeadOfHousehold = true;
	}

}
